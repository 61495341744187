import React from 'react';

const RenderImage = ({ image }) => {
  switch(image) {
    case 'Passion':
      return(
        <g>
          <rect opacity="0.2" x="40" y="22.4142" width="24.8701" height="24.8701" rx="12.435" transform="rotate(45 40 22.4142)" stroke="#FF776E" strokeWidth="2"/>
          <rect opacity="0.5" x="-6.58566" y="40" width="65.8822" height="65.8822" rx="19" transform="rotate(-45 -6.58566 40)" stroke="#FF776E" strokeWidth="2"/>
          <rect x="7" y="7" width="66" height="66" rx="19" stroke="#FF776E" strokeWidth="2" fill="none"/>
        </g>
      )
    case 'Agility':
      return(
        <g>
          <path opacity="0.5" d="M39.9802 76.3206C38.1997 79.0437 34.0861 78.6151 32.9051 75.5835L6.55035 7.92646C5.35559 4.8593 8.17015 1.74238 11.3429 2.61906L71.1328 19.14C73.7446 19.8616 74.8982 22.9166 73.4154 25.1845L39.9802 76.3206Z" stroke="#6EFF8E" strokeWidth="2"/>
          <path opacity="0.2" d="M40.7202 75.6085C39.5587 78.7976 35.2033 79.1821 33.5011 76.2458L2.46679 22.7113C1.06984 20.3016 2.50201 17.2375 5.24678 16.7636L59.1691 7.45322C62.1975 6.93033 64.6599 9.87605 63.6082 12.7637L40.7202 75.6085Z" stroke="#6EFF8E" strokeWidth="2"/>
          <path d="M40.0412 75.9707C38.6213 79.0377 34.2735 79.0706 32.8073 76.0255L1.85278 11.7353C0.574113 9.07956 2.50929 5.99999 5.45679 5.99999L66.1753 5.99999C69.0965 5.99999 71.0325 9.02959 69.8052 11.6805L40.0412 75.9707Z" stroke="#6EFF8E" strokeWidth="2" fill="none"/>
        </g>
      )
    case 'Trust':
      return(
        <g>
          <rect x="1" y="1" width="78" height="78" rx="9" stroke="#6EC2FF" strokeWidth="2"/>
          <rect opacity="0.5" x="40" y="-2.58579" width="60.2254" height="60.2254" rx="9" transform="rotate(45 40 -2.58579)" stroke="#6EC2FF" strokeWidth="2"/>
          <rect opacity="0.2" x="64" y="16" width="48" height="48" rx="9" transform="rotate(90 64 16)" stroke="#6EC2FF" strokeWidth="2"/>
        </g>
      )
    case 'Communication':
      return(
        <g>
          <circle cx="40" cy="40" r="39" stroke="#8B6EFF" strokeWidth="2"/>
          <path opacity="0.5" d="M63.9637 53.4999C58.5308 62.9099 50.9625 69.8496 43.1625 73.488C35.3591 77.1282 27.4169 77.4329 21.081 73.7749C14.745 70.1168 11.0379 63.0863 10.2886 54.5083C9.53969 45.9341 11.7655 35.9099 17.1983 26.4999C22.6311 17.0899 30.1995 10.1503 37.9994 6.51176C45.8029 2.87164 53.745 2.56686 60.081 6.22491C66.4169 9.88297 70.1241 16.9135 70.8733 25.4915C71.6223 34.0657 69.3965 44.0899 63.9637 53.4999Z" stroke="#8B6EFF" strokeWidth="2"/>
          <path opacity="0.2" d="M54.9567 62.8546C45.7592 68.6399 35.8264 71.2436 27.23 70.8199C18.6298 70.3959 11.4639 66.9577 7.56859 60.7648C3.67325 54.5719 3.67708 46.6239 7.01912 38.6882C10.3597 30.7561 17.0078 22.9304 26.2053 17.1451C35.4028 11.3599 45.3356 8.75616 53.932 9.1799C62.5322 9.60383 69.6981 13.0421 73.5934 19.235C77.4887 25.4279 77.4849 33.3759 74.1429 41.3116C70.8023 49.2437 64.1542 57.0694 54.9567 62.8546Z" stroke="#8B6EFF" strokeWidth="2"/>
        </g>
      )
    default:
      return ''
  } 
}

export default RenderImage